.Button {
  background-color: #fff;
  color: #222;
  border-width: 1px;
  border-color: #c7c0c3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-radius: 0.75rem;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Button--fullwidth {
  width: 100%;
}

.Button__Leading {
  margin-right: 0.75rem;
  margin-left: -0.25rem;
}

.Button__Trailing {
  margin-right: -0.25rem;
  margin-left: 0.75rem;
}

.Button--primary {
  background-color: #e84545;
  color: white;
  border-color: transparent;
}
