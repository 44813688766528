.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 50;
}

.Logo {
  height: 100px;
}

.Logo__text {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.08rem;
  line-height: 0.875rem;
}
.Logo__text em {
  font-style: normal;
  color: #e84545;
}

.Header__link {
  text-decoration: none;
  margin: 0.75rem;
  font-weight: 600;
  color: black;
}

.Footer {
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: -1;
  margin-top: 5vh;
}

.FirstFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FirstFooter:nth-child(1) {
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
}

.FirstFooter:nth-child(2) {
  padding-top: 0.5rem;
  border-top: 1px solid rgba(108, 114, 128, 0.2);
  flex-wrap: wrap;
}

.EmailInput {
  background-color: white;
  color: #6c7280;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(108, 114, 128, 0.2);
}

.EmailBtn {
  background-color: #9fa5b1;
  color: #fff;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  margin-left: 0.5rem;
}

.EmailBtn:hover {
  background-color: #bac1cf;
}

@media screen and (max-width: 375px) {
  .containerInputBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .EmailInput {
    width: 100%;
  }
  .EmailBtn {
    width: 100%;
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}

.ErrorMsg {
  color: #d2d6dc;
  font-size: 0.75rem;
}

.NewsletterMsg {
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.NewsletterMsgLink {
  color: #9fa5b1;
  text-decoration: none;
}

.NewsletterMsg span:nth-child(1) {
  color: #9fa5b1;
  font-weight: 700;
  font-size: 0.75rem;
}

.NewsletterMsg span:nth-child(2) {
  color: #9fa5b1;
  font-size: 0.85rem;
}

.SocicalMediaLogos {
  display: flex;
  gap: 1rem;
}
.SocialMedia {
  color: #9fa5b1;
}

.CookieConsentContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 480px;
  max-width: calc(100% - 20px);
  margin: 10px;
  border-radius: 4px;
  box-shadow: 0 9px 15px -2px rgba(0, 0, 0, 0.27);
  padding: 1.5rem;
  z-index: 400;
  background-color: #fff;
}

.CookieConsentOverlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.CookieConsentContent {
  flex: 1;
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 0.8rem;
  font-weight: 600;
}

.CookieConsentButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
