@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
.Button_Button__1N2Gj {
  background-color: #fff;
  color: #222;
  border-width: 1px;
  border-color: #c7c0c3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-radius: 0.75rem;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}

.Button_Button--fullwidth__1RmaU {
  width: 100%;
}

.Button_Button__Leading__3nVgb {
  margin-right: 0.75rem;
  margin-left: -0.25rem;
}

.Button_Button__Trailing__24cX_ {
  margin-right: -0.25rem;
  margin-left: 0.75rem;
}

.Button_Button--primary__3v_3- {
  background-color: #e84545;
  color: white;
  border-color: transparent;
}

.ErrorState_container__1IeHS {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 80vh;
}

.ErrorState_wrapper__2fndr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  max-width: 400px;
  width: 100%;
  padding: 1rem 2rem;
}

.ErrorState_emoji__39UD6 {
  width: 100px;
}

.ErrorState_title__3gJzS {
  margin-bottom: 0;
}

.ErrorState_errorDetails__2oOPJ {
  margin-bottom: 1rem;
  width: 100%;
}

.ErrorState_errorDetailsMessage__3kKrJ {
  padding: 1rem 2rem;
  background: #f3f4f6;
  border-radius: 0.75rem;
}

.ErrorState_errorSummary__hAxuh {
  color: #4b5563;
}

.Icon_Icon__1ge3w {
  width: 20px;
  height: 20px;
}

.Layout_Header__1bYYx {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  padding: 1rem 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  z-index: 50;
}

.Layout_Logo__PW5lw {
  height: 100px;
}

.Layout_Logo__text__2bEkI {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.08rem;
  line-height: 0.875rem;
}
.Layout_Logo__text__2bEkI em {
  font-style: normal;
  color: #e84545;
}

.Layout_Header__link__16ZZZ {
  text-decoration: none;
  margin: 0.75rem;
  font-weight: 600;
  color: black;
}

.Layout_Footer__3Lg_E {
  width: 100%;
  padding: 1rem 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  z-index: -1;
  margin-top: 5vh;
}

.Layout_FirstFooter__KTigj {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.Layout_FirstFooter__KTigj:nth-child(1) {
  padding-bottom: 0.5rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Layout_FirstFooter__KTigj:nth-child(2) {
  padding-top: 0.5rem;
  border-top: 1px solid rgba(108, 114, 128, 0.2);
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Layout_EmailInput__rzDFW {
  background-color: white;
  color: #6c7280;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(108, 114, 128, 0.2);
}

.Layout_EmailBtn__T9nwC {
  background-color: #9fa5b1;
  color: #fff;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  margin-left: 0.5rem;
}

.Layout_EmailBtn__T9nwC:hover {
  background-color: #bac1cf;
}

@media screen and (max-width: 375px) {
  .Layout_containerInputBtn__29Ah3 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .Layout_EmailInput__rzDFW {
    width: 100%;
  }
  .Layout_EmailBtn__T9nwC {
    width: 100%;
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}

.Layout_ErrorMsg__PcRed {
  color: #d2d6dc;
  font-size: 0.75rem;
}

.Layout_NewsletterMsg__1PPj6 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.Layout_NewsletterMsgLink__3NBIG {
  color: #9fa5b1;
  text-decoration: none;
}

.Layout_NewsletterMsg__1PPj6 span:nth-child(1) {
  color: #9fa5b1;
  font-weight: 700;
  font-size: 0.75rem;
}

.Layout_NewsletterMsg__1PPj6 span:nth-child(2) {
  color: #9fa5b1;
  font-size: 0.85rem;
}

.Layout_SocicalMediaLogos__2G1Wi {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.Layout_SocialMedia__t7lY4 {
  color: #9fa5b1;
}

.Layout_CookieConsentContainer__8sw9R {
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 480px;
  max-width: calc(100% - 20px);
  margin: 10px;
  border-radius: 4px;
  box-shadow: 0 9px 15px -2px rgba(0, 0, 0, 0.27);
  padding: 1.5rem;
  z-index: 400;
  background-color: #fff;
}

.Layout_CookieConsentOverlay__1_au8 {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.Layout_CookieConsentContent__1tCrC {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 0.8rem;
  font-weight: 600;
}

.Layout_CookieConsentButtonWrapper__1wDWU {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

.MailChimpForm_form__3q3vr {
  display: -webkit-flex;
  display: flex;
}

.MailChimpForm_input__2g0S0 {
  background-color: white;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  border: 1px solid #dddddd;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 3rem;
}

p {
  line-height: 1.5;
}

