.form {
  display: flex;
}

.input {
  background-color: white;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dddddd;
}
