.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  width: 100%;
  padding: 1rem 2rem;
}

.emoji {
  width: 100px;
}

.title {
  margin-bottom: 0;
}

.errorDetails {
  margin-bottom: 1rem;
  width: 100%;
}

.errorDetailsMessage {
  padding: 1rem 2rem;
  background: #f3f4f6;
  border-radius: 0.75rem;
}

.errorSummary {
  color: #4b5563;
}
